import React from "react"

import draw from "../img/draw.png"
import colours from "../img/colours.png"
import shapes from "../img/shapes.png"
import advanced from "../img/advanced.png"

import InstructionsDownload from "../components/InstructionsDownload"
import Layout from "../components/layout"

const Guide = () => (
  <Layout>
    <div className="container">
      <h1>Quickstart Guide</h1>

      <p>Isometric images can be created on a triangular grid.</p>

      <p>
        You can quickly create an illusion of 3D by using different shades for
        sides in different orientations.
      </p>

      <p>
        Tap to add a point. Tap again. And again. When you tap on the starting
        point the shape you outlined will be drawn.
      </p>

      <img className="guide-image" src={draw} alt="example guide" />

      <p>
        Drag to move around the canvas. Pinch to zoom in and out. Choose colours
        by tapping on them.
      </p>

      <img className="guide-image" src={colours} alt="example guide" />

      <p>
        The colours come in sets of three. One for the left, one for the right
        and one for the top. By consistently applying these colours you can
        easily achieve a 3D effect. There are also secondary sets of colours for
        in-between sides (on iPhone swipe on the colours to see these).
      </p>

      <img className="guide-image" src={shapes} alt="example guide" />

      <p>Tap on X to clear your current shape.</p>

      <p>
        The controls at the top allow you to share/export your drawing (see
        manual), undo the last point or completed shape, redo the action you
        last undid and to edit the layers of the drawing (see manual).
      </p>

      <img className="guide-image" src={advanced} alt="example guide" />

      <InstructionsDownload />
    </div>
  </Layout>
)

export default Guide
